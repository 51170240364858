import { SearchDialog } from '#app/components/search-dialog.js'
import { Input } from '#app/components/ui/input.js'
import { prisma } from '#app/utils/db.server.js'
import { useOptionalUser } from '#app/utils/user.js'
import _ from 'lodash'
import {
	redirect,
	json,
	ActionFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { cn } from '~/utils/misc'
import { Outlet, Form, Link, useLoaderData } from '@remix-run/react'
import { ChevronDownIcon, Search } from 'lucide-react'
import React from 'react'
import { Badge } from '~/components/ui/badge'
import { invariantResponse } from '@epic-web/invariant'
import { getUserId, requireUserId } from '#app/utils/auth.server.js'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import SearchResults, { PartOfSpeech } from '#app/components/search-results.js'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '~/components/ui/accordion'
import { combineFuri } from '~/utils/combine-furi'

export const meta: MetaFunction = () => [
	{ title: 'Setsumei: explain Japanese' },
	{ property: 'og:title', content: 'Setsumei: explain Japanese' },
	{
		name: 'description',
		content:
			'most Japanese to English dictionaries are just synonyms. Setsumi provides detailed explanations of Japanese words without synonyms.',
	},
]

const setsumeiGroups = [
	{
		uniqueIdentifier: '1386460',
		group: [
			{
				id: 'cm18l07zd1wmmmznlkcut900n',
				term: '説明',
				reading: 'せつめい',
				partOfSpeech: 'n vs adj-no',
				extraInfo: 'vs',
				priority: 710,
				definitions:
					'["The act of articulating the content or meaning of a subject in a manner that is comprehensible to the listener, often structured in order to ensure clarity.","A form of communication that clarifies how or why something exists or occurs, seeking to enhance understanding through detailed and logical reasoning.","An explanation that distinguishes itself from mere description by providing insights into the underlying reasons or principles governing a phenomenon, often enabling predictions based on scientific reasoning."]',
				uniqueIdentifier: 1386460,
				tagInfo: 'P ichi news',
				jlptLevel: 4,
				frequencyLevel: 1,
				createdAt: '2024-09-19T00:56:44.137Z',
				updatedAt: '2024-09-19T00:56:44.137Z',
			},
		],
	},
	{
		uniqueIdentifier: '1386470',
		group: [
			{
				id: 'cm18l07zd1wmnmznlrj6xo9ck',
				term: '説明書',
				reading: 'せつめいしょ',
				partOfSpeech: 'n',
				extraInfo: '',
				priority: 605,
				definitions:
					'["A written document that provides detailed guidance or information on how to use, assemble, or operate a product or service.","A manual or booklet that explains the features and functions of a particular item, often accompanied by illustrations or diagrams for clarity.","An educational text that serves to clarify complex concepts or procedures, allowing users to understand the necessary steps required to achieve a specific goal.","A resource that compiles key information and directions to facilitate a better understanding of processes or tasks related to a certain subject or item."]',
				uniqueIdentifier: 1386470,
				tagInfo: 'P news',
				jlptLevel: null,
				frequencyLevel: 10,
				createdAt: '2024-09-19T00:56:44.138Z',
				updatedAt: '2024-09-19T00:56:44.138Z',
			},
		],
	},
	{
		uniqueIdentifier: '1599000',
		group: [
			{
				id: 'cm18kzj5a09jgmznle5nldewv',
				term: '取り扱い説明書',
				reading: 'とりあつかいせつめいしょ',
				partOfSpeech: 'n',
				extraInfo: '',
				priority: 3,
				definitions:
					'["A document that provides detailed guidance on how to use, operate, or maintain a specific product or device.","A written resource that explains the features, functions, and troubleshooting procedures for a particular item, typically intended for the end-user.","A comprehensive guide that outlines safety information, setup instructions, and proper usage practices to ensure optimal performance and user satisfaction."]',
				uniqueIdentifier: 1599000,
				tagInfo: '',
				jlptLevel: null,
				frequencyLevel: 10,
				createdAt: '2024-09-19T00:56:11.951Z',
				updatedAt: '2024-09-19T00:56:11.951Z',
			},
			{
				id: 'cm18kzj5b09jhmznlcdqxrvcs',
				term: '取扱説明書',
				reading: 'とりあつかいせつめいしょ',
				partOfSpeech: 'n',
				extraInfo: '',
				priority: 3,
				definitions:
					'["A document that provides guidance on how to operate or use a specific product, including details about its features and any necessary precautions.","A comprehensive guide designed for users, outlining the proper procedures for installation, operation, and maintenance of a device or system.","A written resource that explains the functionalities of a product, often including troubleshooting tips and safety instructions to ensure effective and safe use."]',
				uniqueIdentifier: 1599000,
				tagInfo: '',
				jlptLevel: null,
				frequencyLevel: 10,
				createdAt: '2024-09-19T00:56:11.951Z',
				updatedAt: '2024-09-19T00:56:11.951Z',
			},
		],
	},
	{
		uniqueIdentifier: '1802690',
		group: [
			{
				id: 'cm18kzna80jdhmznl98mlmrhk',
				term: '説明文',
				reading: 'せつめいぶん',
				partOfSpeech: 'n',
				extraInfo: '',
				priority: 102,
				definitions:
					'["A type of writing that provides a clear and logical explanation of a particular subject or event, distinguishing itself from literary works by focusing on practical information.","A written composition aimed at conveying the details and rationale of something in an organized manner, making it understandable to the reader.","An objective text that describes and clarifies the characteristics or circumstances of a specific topic, often used in educational settings to teach practical writing skills.","A form of writing that serves to inform or elucidate certain facts or processes, characterized by its structured presentation of information rather than narrative or poetic elements."]',
				uniqueIdentifier: 1802690,
				tagInfo: 'news',
				jlptLevel: null,
				frequencyLevel: 10,
				createdAt: '2024-09-19T00:56:17.313Z',
				updatedAt: '2024-09-19T00:56:17.313Z',
			},
		],
	},
	{
		uniqueIdentifier: '1946800',
		group: [
			{
				id: 'cm18kzoiy0n7kmznl0mp3iadw',
				term: '補足説明',
				reading: 'ほそくせつめい',
				partOfSpeech: 'n adj-no',
				extraInfo: '',
				priority: 2,
				definitions:
					'["An additional clarification provided to enhance understanding of a main point or concept.","A detailed explanation meant to elaborate on or support the primary information presented.","Extra information given to fill in gaps or provide context for a specific subject matter.","An explanatory note added to assist in grasping the significance or intricacies of a topic."]',
				uniqueIdentifier: 1946800,
				tagInfo: '',
				jlptLevel: null,
				frequencyLevel: 10,
				createdAt: '2024-09-19T00:56:18.923Z',
				updatedAt: '2024-09-19T00:56:18.923Z',
			},
		],
	},
]

const FREE_SEARCH_LIMIT = 30

export const loader = async ({ request }) => {
	const userId = await getUserId(request)
	let search
	if (!userId) {
		search = '説明'
		return json({ status: 'idle', termGroups: setsumeiGroups })
	} else {
		search = new URL(request.url).searchParams.get('search')
		if (search === '') {
			return redirect('/')
		}
	}

	if (!search) {
		return json({ status: 'idle', termGroups: [] })
	}

	const user = await prisma.user.findUnique({
		where: { id: userId },
		select: {
			email: true,
			roles: { select: { name: true } },
			trialUsage: true,
		},
	})
	invariantResponse(user, 'User not found', { status: 404 })

	const hasV1Access = user.roles.some((role) => role.name === 'v1 Owner')

	if (user.trialUsage > FREE_SEARCH_LIMIT && !hasV1Access) {
		return redirect('/upgrade')
	}

	const terms = await prisma.termEntries.findMany({
		where: {
			OR: [
				{
					term: {
						contains: `${search}`,
					},
				},
				{
					reading: {
						equals: `${search}`,
					},
				},
			],
		},
	})

	if (!terms || terms.length === 0) {
		return json({ status: 'idle', termGroups: [] })
	}

	const uniqueIdentifiers = terms.map((term) => term.uniqueIdentifier)

	const relatedTerms = await prisma.termEntries.findMany({
		where: {
			uniqueIdentifier: {
				in: uniqueIdentifiers as number[], // Equivalent to `inArray`
			},
		},
	})

	const groupedTerms = _.groupBy(relatedTerms, 'uniqueIdentifier')

	const filteredTerms = Object.fromEntries(
		Object.entries(groupedTerms).map(([key, group]) => [
			key,
			_.uniqBy(group, (term) => `${term.term}-${term.reading}`),
		]),
	)

	// Convert the grouped terms object into an array of groups
	const termGroups = Object.entries(filteredTerms).map(
		([uniqueIdentifier, group]) => ({
			uniqueIdentifier,
			group: group.sort(
				(a, b) => (a.frequencyLevel || 10) - (b.frequencyLevel || 10),
			), // Sort by frequencyLevel within the group
		}),
	)

	// Sort the groups by the highest priority term in each group
	termGroups.sort((a, b) => {
		const exactMatchA = a.group.some((term) => term.reading === search)
		const exactMatchB = b.group.some((term) => term.reading === search)

		if (exactMatchA && !exactMatchB) return -1
		if (!exactMatchA && exactMatchB) return 1

		const highestPriorityA = a.group[0]?.frequencyLevel || 10
		const highestPriorityB = b.group[0]?.frequencyLevel || 10

		return highestPriorityA - highestPriorityB
	})

	if (!hasV1Access) {
		await prisma.user.update({
			where: { id: userId },
			data: {
				trialUsage: user.trialUsage + 1,
			},
		})
	}

	return json({ status: 'idle', termGroups: termGroups.slice(0, 5) })
}

export default function Index() {
	const loggedInUser = useOptionalUser()

	const [openIndex, setOpenIndex] = React.useState<number | null>(0)

	const toggleItem = (index: number) => {
		setOpenIndex(openIndex === index ? null : index)
	}

	const { termGroups, status } = useLoaderData<typeof loader>()

	return (
		<TooltipProvider>
			{!loggedInUser && (
				<Link to="/" className="mb-6 mt-2 flex items-center justify-center">
					<h1 className="relative -ml-6 text-center font-sans text-[4rem] font-bold leading-none text-primary">
						<span className="mr-8 inline-block -skew-x-12 transform">
							Setsumei
						</span>
						<span
							lang="ja"
							className="absolute right-[-2rem] top-0 font-serif text-[2rem] leading-tight"
							style={{
								writingMode: 'vertical-rl',
								textOrientation: 'upright',
							}}
						>
							説明
						</span>
					</h1>
				</Link>
			)}
			{loggedInUser && <SearchDialog status={status} autoFocus autoSubmit />}
			{loggedInUser && termGroups.length > 0 && (
				<SearchResults termGroups={termGroups} />
			)}
			{!loggedInUser && (
				<div className="hero">
					<div className="hero-content flex-col-reverse md:h-[700px] md:flex-row md:items-center md:px-6">
						<div className="-my-20 scale-75 opacity-90 md:-my-0">
							<div className="relative flex w-full flex-col items-center justify-center">
								<div className="relative flex-1 md:grow-0">
									<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
									<Input
										name="search"
										type="search"
										defaultValue={'説明'}
										disabled
										placeholder="Search..."
										className="w-[398px] rounded-lg bg-white pl-8 md:w-[800px] md:max-w-lg"
									/>
								</div>
							</div>
							<div className="mx-auto mt-4 max-w-lg rounded-lg bg-white p-6 shadow-md dark:border dark:border-input dark:bg-black dark:text-white">
								<div className="space-y-10">
									<Accordion>
										{termGroups.map(({ uniqueIdentifier, group }, index) => {
											const topTerm = group[0]

											return (
												<AccordionItem
													isLast={index === termGroups.length - 1}
													key={uniqueIdentifier}
												>
													<AccordionTrigger
														isOpen={openIndex === index}
														onClick={() => toggleItem(index)}
													>
														<div className="flex items-center space-x-4">
															{group.map((g, gIndex) => {
																const pairs = combineFuri(g.term, g.reading)
																return (
																	<span
																		lang="ja"
																		className={cn(
																			'font-japanese inline-flex flex-wrap p-1 text-gray-900 dark:text-gray-100',
																			{
																				'rounded border bg-secondary dark:border-input dark:text-gray-500':
																					gIndex !== 0,
																			},
																		)}
																		key={g.id}
																	>
																		{pairs.map(
																			([furiText, text], index: number) => (
																				<span
																					lang="ja"
																					key={text + index}
																					className="inline-flex flex-col items-center justify-end self-end font-serif text-3xl font-bold leading-none"
																				>
																					<span
																						lang="ja"
																						className="mx-[0.1em] block select-none pb-[0.1em] pt-[0.2em] text-[0.5em] tracking-[-0.02em] opacity-90"
																					>
																						{furiText}
																					</span>
																					<span lang="ja" className="block">
																						{text}
																					</span>
																				</span>
																			),
																		)}
																	</span>
																)
															})}
														</div>
														<div className="flex items-center space-x-4">
															{topTerm.jlptLevel && (
																<Tooltip>
																	<TooltipTrigger className="">
																		<Badge variant="gradient">{`N${topTerm.jlptLevel}`}</Badge>
																	</TooltipTrigger>
																	<TooltipContent>
																		<p>The JLPT level of the word</p>
																	</TooltipContent>
																</Tooltip>
															)}
															{topTerm.frequencyLevel && (
																<Tooltip>
																	<TooltipTrigger>
																		<Badge
																			className="mb-1.5"
																			variant={
																				topTerm.frequencyLevel <= 5
																					? 'diamond'
																					: 'diamond-dull'
																			}
																		>{`${topTerm.frequencyLevel}`}</Badge>
																	</TooltipTrigger>
																	<TooltipContent>
																		<p>
																			Our ranking from 1 to 10 of how useful the
																			word is
																		</p>
																	</TooltipContent>
																</Tooltip>
															)}
															{termGroups.length > 1 && (
																<ChevronDownIcon
																	className={`h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ${
																		openIndex === index && 'rotate-180'
																	}`}
																/>
															)}
														</div>
													</AccordionTrigger>
													<AccordionContent isOpen={openIndex === index}>
														<div className="flex items-center justify-between py-1">
															<div className="flex space-x-2">
																{topTerm.partOfSpeech.split(' ').map((pos) => (
																	<PartOfSpeech key={pos} pos={pos} />
																))}
															</div>
														</div>
														<div className="md:text-md space-y-3 text-sm">
															{topTerm.definitions &&
																JSON.parse(topTerm.definitions).map(
																	(definition: string, index: number) => (
																		<div key={index} className="flex space-x-2">
																			<span className="font-serif text-gray-700 dark:text-gray-300">
																				{index + 1}.
																			</span>
																			<p className="font-serif text-gray-800 dark:text-gray-200">
																				{definition}
																			</p>
																		</div>
																	),
																)}
														</div>
													</AccordionContent>
												</AccordionItem>
											)
										})}
									</Accordion>
								</div>
							</div>
						</div>

						<div className="flex flex-col items-center space-y-8 md:items-start md:space-y-12">
							<h1 className="text-center text-4xl font-black leading-tight tracking-tight md:text-left md:text-5xl md:leading-tight lg:text-5xl lg:leading-tight">
								Don't let imprecise translations mess up your Japanese
							</h1>
							<p className="text-base-content-secondary text-center text-lg leading-relaxed md:text-left">
								Most dictionaries give you synonyms—but synonyms can mislead.
								Setsumei gives you deeper explanations, so you understand each
								word’s true meaning.
							</p>
							<Link to="/signup">
								<button className="btn btn-primary mt-6">Get Started</button>
							</Link>
						</div>
					</div>
				</div>
			)}
			{!loggedInUser && (
				<article className="text-primary">
					<main className="flex min-h-screen flex-col items-center justify-center">
						<section id="learn-more" className="w-full">
							<div className="bg-black px-10 py-20">
								<div className="mx-auto max-w-4xl">
									<div className="mb-12">
										<h3 className="text-3xl font-bold">
											More Than Just Synonyms
										</h3>
										<p className="mt-8">
											Most Japanese-English dictionaries rely on basic synonyms,
											leaving you vulnerable to misinterpreting nuances and
											context. But Japanese is a language{' '}
											<b>rich in subtlety</b>, where one word can convey
											multiple layers of meaning that simple translations
											completely miss. This is why relying on oversimplified
											definitions can actually <b>hold you back</b>.
										</p>

										<p className="mt-8">
											Setsumei is different. For every word, it provides
											in-depth explanations that guide you as though you don’t
											know the English equivalent at all. Instead, you learn why
											the word is used, how it fits into sentences, and what
											nuances set it apart in Japanese
										</p>

										<p className="mt-8">
											If you’re serious about mastering Japanese, can you afford
											to keep ingraining imprecist translations into your head?
										</p>
										<div className="mt-20 flex flex-col items-center justify-center space-y-2 md:flex-row md:space-x-10">
											<img
												src="/jisho.jpg"
												alt="Comparison with Jisho"
												className="hidden h-[300px] rounded-3xl border-2 border-black md:block"
											/>
											<img
												src="/setsumei-example.jpg"
												alt="Comparison with Jisho"
												className="hidden h-[300px] rounded-3xl border-2 border-base-content/20 md:block"
											/>
											<p className="md:hidden">Normal dictionary</p>
											<img
												src="/jisho-small.jpg"
												alt="Comparison with Jisho"
												className="max-w-xl rounded-3xl border-2 border-black md:hidden"
											/>
											<p className="pt-10 md:hidden">Setsumei explanation</p>
											<img
												src="/setsumei-small.jpg"
												alt="Comparison with Jisho"
												className="max-w-xl rounded-3xl border-2 border-base-content/20 md:hidden"
											/>
										</div>
									</div>

									{/* <div className="mb-12"> */}
									{/* 	<h3 className="text-3xl font-bold"> */}
									{/* 		Avoid False Equivalency */}
									{/* 	</h3> */}
									{/* 	<p className="mt-4"> */}
									{/* 		Language isn't always a 1:1 match. Often, there are */}
									{/* 		concepts or ways of speaking that don't have a direct */}
									{/* 		translation. Relying on direct translations can lead to */}
									{/* 		misunderstandings and unnatural speech. Setsumei avoids */}
									{/* 		this problem by intentionally steering clear of direct */}
									{/* 		English equivalents and instead provides comprehensive */}
									{/* 		explanations. */}
									{/* 	</p> */}

									{/* 	<div className="mt-8 rounded-lg bg-base-100 p-6 shadow-md"> */}
									{/* 		<h4 className="text-xl font-bold"> */}
									{/* 			Example: The Complexity of "懐かしい" (natsukashii) */}
									{/* 		</h4> */}
									{/* 		<p className="mt-4"> */}
									{/* 			Many dictionaries translate "懐かしい" as "nostalgic." */}
									{/* 			However, in Japanese, it encompasses feelings of missing */}
									{/* 			something dear, a longing for the past, and warmth from */}
									{/* 			fond memories. Simply equating it to "nostalgic" doesn't */}
									{/* 			capture its full emotional depth, potentially leading to */}
									{/* 			a shallow understanding. */}
									{/* 		</p> */}
									{/* 	</div> */}
									{/* </div> */}
								</div>
							</div>
						</section>

						<section className="hero bg-base-200 px-2 py-20">
							<div className="hero-content mx-auto flex max-w-4xl flex-col items-start justify-start">
								<h3 className="text-3xl font-bold">One-Time Fee</h3>
								<p className="mt-8">
									Setsumei is like a digital book, priced at ¥3000 JPY converted
									to your local currency. This one-time fee grants you full
									access to the first edition. We use fine-tuned AI models to
									assist with explanation production. As the models improve, so
									will Setsumei, and we may release subsequent editions with
									enhancements.
								</p>
							</div>
						</section>

						<section className="hero bg-black px-2 py-20">
							<div className="hero-content text-center">
								<div className="max-w-md">
									<h1 className="text-4xl font-bold">
										Ready to stop making translation errors?
									</h1>
									<p className="py-6">
										Get started with Setsumei today and ensure you are
										engraining the correct meanings of words in your head as you
										learn.
									</p>

									<Link to="/signup">
										<button className="btn btn-primary">
											Start Free Trial
										</button>
									</Link>
								</div>
							</div>
						</section>
					</main>
				</article>
			)}
			<Outlet />
			{!loggedInUser && (
				<a
					className="group fixed bottom-0 right-0 z-10 cursor-pointer rounded-tl-xl border-l border-t border-base-content/20 bg-base-100 px-4 py-2 text-sm font-semibold duration-200 hover:bg-base-200"
					href="https://twitter.com/willebunting"
					target="_blank"
					rel="noreferrer"
				>
					<div className="flex flex-row items-center justify-center gap-1.5 text-center">
						<div>
							Made by <span className="link link-accent">Will</span>
						</div>
						<div className="avatar -mb-1 -mt-1">
							<div className="relative w-7 rounded-full duration-300 group-hover:scale-110">
								<img
									className="h-8 w-8 rounded-full object-cover"
									alt="twitter biopic"
									src="/profile-circle.png"
									style={{ color: 'transparent' }}
								/>
							</div>
						</div>
					</div>
				</a>
			)}
		</TooltipProvider>
	)
}
